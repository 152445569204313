import React, { createContext, useState, ReactNode, useEffect } from 'react';
import { FrameDetails, SelectOption, TimelineDetails } from '../components/center/hud/contact/utils';
import { get_formated_time } from '../utils';
import { calculate_frame_slots, get_current_slot, roundup_end_time, roundup_start_time } from '../hooks/utils';
import useUrlParams from '../hooks/useUrlParams';
import useScenario from '../hooks/useScenario';
import { update_sat_orbit_path } from '../hooks/cesiumUtils';
import * as Cesium from "cesium";

interface TimelineContextType {
    loading: boolean;
    timelist: { date: string, time: string, epoch: number }[];
    window_width: number;
    selected_timeframe: SelectOption;
    selected_contact: SelectOption;
    timeframe_details: FrameDetails;
    timeline_container: TimelineDetails;
    set_timeline_container: (details: TimelineDetails) => void;
    set_loading: (loading: boolean) => void;
    set_timelist: (list: { date: string, time: string, epoch: number }[]) => void;
    set_window_width: (width: number) => void;
    set_selected_contact: (option: SelectOption) => void;
    set_timeframe_details: (details: FrameDetails) => void;
    select_timeframe: (option: SelectOption) => void;
    get_time_list: (time: number, timeframe_duration: number) => void;
    change_frame: (side: string, current_time: number) => void;
    set_cesium_time: (viewer: Cesium.Viewer | null, time: number) => void
}

export const TimelineContext = createContext<TimelineContextType | undefined>(undefined);

interface TimelineProviderProps {
    children: ReactNode;
}

const TimelineProvider = ({ children }: TimelineProviderProps) => {
    const { dashboard } = useUrlParams()
    const { set_simulation_time, simulation_time, } = useScenario()
    const [loading, set_loading] = useState<boolean>(true);
    const [window_width, set_window_width] = useState<number>(window.innerWidth);
    const [selected_contact, set_selected_contact] = useState<SelectOption>({
        label: dashboard === 'operate' ? 'GROUND CONTACT' : 'GROUND & ONBOARD',
        value: dashboard === 'operate' ? 'ground_contact' : 'ground_&_on_board'
    });
    const [selected_timeframe, set_selected_timeframe] = useState<SelectOption>({
        label: '30 Min',
        value: 30,
    });
    const [timeline_container, set_timeline_container] = useState<TimelineDetails>({
        start_time: 0,
        end_time: 0,
        slots: []
    });
    const [timeframe_details, set_timeframe_details] = useState<FrameDetails>({
        frame_start_time: 0,
        frame_end_time: 0,
        frame_duration: 1800,
        fream_step: 0,
        manual_change: false
    });

    const [timelist, set_timelist] = useState<{ date: string, time: string, epoch: number }[]>([]);
    const { frame_duration } = timeframe_details

    useEffect(() => {
        const simulation_start_time = simulation_time.start_time
        const simulation_end_time = simulation_time.end_time
        if (simulation_start_time && simulation_end_time) {
            const timeline_container_start_time = roundup_start_time(simulation_start_time)
            const timeline_container_end_time = roundup_end_time(simulation_end_time)
            const timeline_container_slots = calculate_frame_slots(timeline_container_start_time, timeline_container_end_time, frame_duration)
            set_timeline_container({
                start_time: timeline_container_start_time,
                end_time: timeline_container_end_time,
                slots: timeline_container_slots
            })
        }
    }, [simulation_time, frame_duration])

    const select_timeframe = (option: SelectOption) => {
        const frame_start_time = timeframe_details.frame_start_time
        const frame_duration = option.value as number * 60;
        get_time_list(frame_start_time, frame_duration);
        const frame_end_time = frame_start_time + frame_duration;
        set_selected_timeframe(option);
        set_timeframe_details({
            ...timeframe_details,
            frame_start_time: frame_start_time,
            frame_end_time: frame_end_time,
            frame_duration: frame_duration,
        })
    }

    const get_time_list = (epoch_start_time: number, timeframe_duration: number) => {

        if (epoch_start_time) {
            const epoch_end_time = epoch_start_time + timeframe_duration;
            const epoch_center_time = ((epoch_start_time! + epoch_end_time) / 2);

            const start_time = {
                date: `${get_formated_time(epoch_start_time)?.split('T')?.[0]}T`,
                time: `${get_formated_time(epoch_start_time!)?.split('T')?.[1]}`,
                epoch: epoch_start_time!
            }
            const end_time = {
                date: `${get_formated_time(epoch_end_time)?.split('T')?.[0]}T`,
                time: `${get_formated_time(epoch_end_time)!.split('T')?.[1]}`,
                epoch: epoch_end_time!
            }
            const center_time = {
                date: `${get_formated_time(epoch_center_time)?.split('T')?.[0]}T`,
                time: `${get_formated_time(epoch_center_time)!.split('T')?.[1]}`,
                epoch: epoch_center_time!
            }
            const time_list: { date: string, time: string, epoch: number }[] = [start_time, center_time, end_time];
            set_timelist(time_list);
            set_loading(false)
            return time_list;
        }

        set_loading(false)
    }

    const set_frame_start_time = (start_time: number, manual_change: boolean | string) => {
        const frame_duration = timeframe_details.frame_duration;
        set_timeframe_details((prev) => ({
            ...prev,
            frame_start_time: start_time,
            frame_end_time: start_time + frame_duration,
            manual_change: manual_change
        }))
    }

    const change_frame = (side: string, current_time: number) => {
        const simulation_start_time: number = simulation_time.start_time
        const simulation_end_time: number = simulation_time.end_time
        const frame_start_time = timeframe_details.frame_start_time;
        const timeframe_duration = timeframe_details.frame_duration;
        const half_timeframe_duration = timeframe_details.frame_duration / 2;
        const first_time_slot = timeline_container?.slots[0]
        if (side === 'left') {
            let new_start_time = frame_start_time - half_timeframe_duration
            if (new_start_time <= first_time_slot[0]) {
                new_start_time = first_time_slot[0]
            }
            const timelist = get_time_list(new_start_time, timeframe_duration);
            if (timelist) {
                const marker_in_frame = current_time >= timelist[0]?.epoch && current_time <= timelist[2]?.epoch ? false : 'left'
                set_frame_start_time(new_start_time, marker_in_frame)
                if (timelist[0]?.epoch <= simulation_start_time && dashboard === 'operate') {
                    const simulation_duration = simulation_time?.duration
                    const new_simulation_start_time = simulation_start_time - simulation_duration
                    const new_simulation_end_time = new_simulation_start_time + simulation_duration
                    set_simulation_time({
                        ...simulation_time,
                        start_time: new_simulation_start_time,
                        end_time: new_simulation_end_time,
                    })
                }
            }
        } else {
            let new_start_time = frame_start_time + half_timeframe_duration
            const last_time_slot = timeline_container?.slots[timeline_container?.slots?.length - 1]
            if (new_start_time >= last_time_slot[0]) {
                new_start_time = last_time_slot[0]
            }
            const timelist = get_time_list(new_start_time, timeframe_duration);
            if (timelist) {
                const marker_in_frame = current_time >= timelist[0]?.epoch && current_time <= timelist[2]?.epoch ? false : 'right'
                set_frame_start_time(new_start_time, marker_in_frame)
                if (timelist[2]?.epoch >= simulation_end_time && dashboard === 'operate') {
                    const simulation_duration = simulation_time?.duration
                    const new_simulation_end_time = simulation_end_time + simulation_duration
                    set_simulation_time({
                        ...simulation_time,
                        start_time: simulation_end_time,
                        end_time: new_simulation_end_time,
                    })
                }
            }
        }
    }


    const set_cesium_time = async (viewer: Cesium.Viewer | null, time: number) => {
        const current_time = time / 1000
        const frame_change_manually = timeframe_details.manual_change
        const timeframe_duration = timeframe_details.frame_duration;
        const all_time_slots = timeline_container.slots
        const current_time_frame = await get_current_slot(current_time, all_time_slots);
        // update_sat_orbit_path(viewer, time)
        if (current_time_frame?.length && !frame_change_manually) {
            get_time_list(current_time_frame?.[0], timeframe_duration);
            set_frame_start_time(current_time_frame?.[0], false)
        }
    }

    return (
        <TimelineContext.Provider
            value={{
                loading,
                timelist,
                set_loading,
                set_timelist,
                window_width,
                set_window_width,
                select_timeframe,
                selected_contact,
                timeframe_details,
                selected_timeframe,
                timeline_container,
                set_timeline_container,
                set_selected_contact,
                set_timeframe_details,
                get_time_list,
                change_frame,
                set_cesium_time,
            }}>
            {children}
        </TimelineContext.Provider>
    )
}

export default TimelineProvider