import { FC } from "react"
import * as Cesium from "cesium"
import './GroundTargetCard.css'
import { truncateNumber } from "./CommonFunctions";
import { LatLongSvg, AltitudeSvg } from "./svgs/Svgs";

interface GsProps {
    target: any;
    viewer: any
}
const GroundTargetCard: FC<GsProps> = ({ target, viewer }) => {
    if (target && viewer) {
        let targetName = target?.name ?? target?.groundTargetName;
        let targetLong = target?.longitude ?? target.position[0];
        let targetLat = target?.latitude ?? target.position[1];
        let target_altitude = target?.altitude ?? target.position[2];
        var cartographic = Cesium.Cartographic.fromDegrees(targetLong, targetLat);
        var cartesianPosition = Cesium.Cartographic.toCartesian(cartographic);
        const popupPosition = Cesium.SceneTransforms.wgs84ToWindowCoordinates(
            viewer.scene,
            cartesianPosition
        );

        let outside_window_x = (popupPosition.x + (370)) > window.innerWidth;
        let top = `${popupPosition.y}px`;
        let left = outside_window_x ? `${popupPosition.x - (370)}px` : `${popupPosition.x + 10}px`;

        return (
            <div className="popup-modal"
                key={target?.['groundTargetId']}
                style={{
                    display: 'flex',
                    flexDirection: "row",
                    alignContent: 'center',
                    justifyContent: 'space-between',
                    cursor: 'pointer',
                    left: left,
                    top: top,
                    transition: 'all 0.3s ease',
                    padding: '8pt',
                    width: '300pt',
                }}>
                <div className='d-flex align-items-start flex-column'>
                    <div className='d-flex align-items-center w-60'>
                        <span className='fs-14 w-auto color-F0F0F0 text-truncate' title={targetName}>
                            {targetName}
                        </span>

                    </div>
                    <span className='fs-12 color_history text-truncate'>
                        GeoFeature
                    </span>
                </div>
                <div className='align-items-center justify-content-center gap-2 d-flex show__position fs-12'>
                    <div className="d-flex align-items-center gap-2 ">
                        <LatLongSvg />
                        <span>{targetLat === undefined ? 'NA' : truncateNumber(targetLat, 2)}<sup>o</sup>, {targetLong === undefined ? 'NA' : truncateNumber(targetLong, 2)}<sup>o</sup></span>
                    </div>
                    <div className="d-flex gap-2">
                        <div className="d-flex align-items-center">
                            <AltitudeSvg />
                            <span className="color_history">H</span>
                        </div>
                        <span>{target_altitude === undefined ? 'NA' : truncateNumber(target_altitude, 2) + ' m'}</span>
                    </div>
                </div>
            </div >
        )


    }
    return null
}

export default GroundTargetCard