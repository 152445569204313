//@ts-nocheck
import React, { useEffect, useState } from "react";
import styles from "./PageHeader.module.scss";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";

import { useSelector } from 'react-redux';
import {
    usageStore,
} from '../../store/UsageSlice';
import {
    MissionStore,
} from '../../store/MissionSlice';
import { UpdateDonut } from '../../Data/DataChart/DataApexChart';
import { truncateNumber } from "../../components/CommonComponents/CommonFunctions";


const PageHeader = (props: any) => {

    const aopCalculateUsage = useSelector((state: { usage: usageStore }) => state.usage.aopCalculateUsage);

    const edgeSizeUsage = useSelector((state: { usage: usageStore }) => state.usage.edgeSizeUsage);
    const edgeWeightUsage = useSelector((state: { usage: usageStore }) => state.usage.edgeWeightUsage);
    const edgePowerUsage = useSelector((state: { usage: usageStore }) => state.usage.edgePowerUsage);

    const edgeSizePercent = useSelector((state: { usage: usageStore }) => state.usage.edgePerCent.size);
    const edgeWtPercent = useSelector((state: { usage: usageStore }) => state.usage.edgePerCent.weight);
    const edgePowerPercent = useSelector((state: { usage: usageStore }) => state.usage.edgePerCent.power);

    const sizeUsage = useSelector((state: { usage: usageStore }) => state.usage.payloadSizeUsage);
    const weightUsage = useSelector((state: { usage: usageStore }) => state.usage.payloadWeightUsage);
    const powerUsage = useSelector((state: { usage: usageStore }) => state.usage.payloadPowerUsage);

    const payloadSizePercent = useSelector((state: { usage: usageStore }) => state.usage.payloadPerCent.size);
    const payloadWtPercent = useSelector((state: { usage: usageStore }) => state.usage.payloadPerCent.weight);
    const payloadPowerPercent = useSelector((state: { usage: usageStore }) => state.usage.payloadPerCent.power);

    const busSizeUsage = useSelector((state: { usage: usageStore }) => state.usage.busSizeUsage);
    const busWeightUsage = useSelector((state: { usage: usageStore }) => state.usage.busWeightUsage);
    const busPowerUsage = useSelector((state: { usage: usageStore }) => state.usage.busPowerUsage);

    const busSizePercent = useSelector((state: { usage: usageStore }) => state.usage.busPerCent.size);
    const busWtPercent = useSelector((state: { usage: usageStore }) => state.usage.busPerCent.weight);
    const busPowerPercent = useSelector((state: { usage: usageStore }) => state.usage.busPerCent.power);

    const maxSizeUsage = useSelector((state: { usage: usageStore }) => state.usage.maxSizeUsage);
    const maxWeightUsage = useSelector((state: { usage: usageStore }) => state.usage.maxWeightUsage);
    const maxPowerUsage = useSelector((state: { usage: usageStore }) => state.usage.maxPowerUsage);

    const isNewPayloadCatelog = useSelector((state: { mission: MissionStore }) => state.mission.isNewPayloadCatelog);

    const [ddToggle, setDDToggle] = useState(false);
    ////////////...................................//////////////////

    useEffect(() => {
        setDDToggle(false);
    }, [maxSizeUsage, sizeUsage]);

    const getBgColor = (val: number) => {
        return 'conic-gradient(#aaa 0% ' + (val - 5) + '%, #1e293b ' + (val) + '% 100%)';
    }

    const getGaugeColor = (val: number) => {
        return '#aaa';
    }

    const openCloseDD = () => {
        setDDToggle(!ddToggle);
    };

    const calculate_percentage = (number: number) => {
        if (Number.isInteger(number) && number % 10 === 0) {
            number = Math.floor(number / 10) * 10;
        }
        return number;
    }

    const bus_size_usage = parseFloat(truncateNumber(busSizeUsage, 2))
    const payload_size_usage = parseFloat(truncateNumber(sizeUsage, 2))
    const edge_size_usage = parseFloat(truncateNumber(edgeSizeUsage, 2))
    const un_used_size = parseFloat(truncateNumber(maxSizeUsage - (busSizeUsage + sizeUsage + edgeSizeUsage), 2))

    const bus_weight_usage = parseFloat(truncateNumber(busWeightUsage, 2))
    const payload_weight_usage = parseFloat(truncateNumber(weightUsage, 2))
    const edge_weight_usage = parseFloat(truncateNumber(edgeWeightUsage, 2))
    const un_used_weight = parseFloat(truncateNumber(maxWeightUsage - (busWeightUsage + weightUsage + edgeWeightUsage), 2))

    const bus_power_usage = parseFloat(truncateNumber(busPowerUsage, 2))
    const payload_power_usage = parseFloat(truncateNumber(powerUsage, 2))
    const edge_power_usage = parseFloat(truncateNumber(edgePowerUsage, 2))
    const un_used_power = parseFloat(truncateNumber(maxPowerUsage - (busPowerUsage + powerUsage + edgePowerUsage), 2))

    return (
        <div className={styles.PageHeader}>
            {/* <!-- PAGE-HEADER --> */}

            <div className="page-header ">

                <div className="d-flex position-absolute mt-4" style={{ left: 50 }}>
                    <h1 className="page-title">
                        {props?.['sat_name'] && <span className="mission-name" title={props.sat_name}>{props.sat_name?.length > 10 ? props.sat_name.substring(0, 10) + " ..." : props.sat_name} {props.titles !== '' && ':'}</span>}{" "}
                        <span className="fs-18 ms-2">{props.titles}</span>
                        {props.titles !== '' && <OverlayTrigger
                            placement="bottom"
                            overlay={
                                <Popover id="popover-basic">
                                    <Popover.Body>
                                        <span className='color-text text-center d-flex'>{props.description}</span>
                                    </Popover.Body>
                                </Popover>

                            }
                        >
                            {props.description !== '' && <sup>
                                <i className="fe fe-info ms-1 fs-14 text-info"></i>
                            </sup>}
                        </OverlayTrigger>}
                    </h1>

                    {/*<span className="page-description">
                {props.description}
            </span>*/}
                </div>



                {(!isNewPayloadCatelog && props?.showSwap) && <div className="d-flex position-absolute" style={{ left: '39%' }}>
                    <div className="m-auto d-flex">
                        <div className="mission-stats">
                            {props?.showPercent && <div className="guage"
                                style={{ background: getBgColor(payloadSizePercent + busSizePercent + edgeSizePercent) }}>
                                <div className="guage-inner" style={{ color: getGaugeColor(payloadSizePercent + busSizePercent + edgeSizePercent) }}>
                                    {calculate_percentage(payloadSizePercent + busSizePercent + edgeSizePercent)}%
                                </div>
                            </div>}
                            {(!props?.showPercent) && <img src={require('../../assets/images/icons/size.png')} alt={''} />}
                            <div className="d-flex flex-column">
                                {!props?.showPercent && <span className="stats-value">{truncateNumber(sizeUsage + edgeSizeUsage, 2)} Units</span>}
                                {(props?.showPercent && props?.type === '') && <span className="stats-value">{truncateNumber(sizeUsage + busSizeUsage + edgeSizeUsage, 2)} Units</span>}
                                {(props?.showPercent && props?.type === 'espa') && <span className="stats-value">{props?.['value']} {props?.type === 'espa' ? '' : 'Units'}</span>}
                                <span className="stats-title">{'Size'}</span>
                            </div>
                        </div>
                        <div className="mission-stats">
                            {props?.showPercent &&
                                <div className="guage" style={{ background: getBgColor(payloadWtPercent + busWtPercent + edgeWtPercent) }}>
                                    <div className="guage-inner" style={{ color: getGaugeColor(payloadWtPercent + busWtPercent + edgeWtPercent) }}>
                                        {calculate_percentage(payloadWtPercent + busWtPercent + edgeWtPercent)}%
                                    </div>
                                </div>}
                            {(!props?.showPercent) && <img src={require('../../assets/images/icons/weight.png')} alt={''} />}
                            <div className="d-flex flex-column">
                                {!props?.showPercent && <span className="stats-value">{truncateNumber(weightUsage + edgeWeightUsage, 2)} kg</span>}
                                {props?.showPercent && <span className="stats-value">{truncateNumber(weightUsage + busWeightUsage + edgeWeightUsage, 2)} kg</span>}
                                <span className="stats-title">Mass</span>
                            </div>
                        </div>
                        <div className="mission-stats">
                            {props?.showPercent &&
                                <div className="guage" style={{ background: getBgColor(payloadPowerPercent + busPowerPercent + edgePowerPercent) }}>
                                    <div className="guage-inner" style={{ color: getGaugeColor(payloadPowerPercent + busPowerPercent + edgePowerPercent) }}>
                                        {calculate_percentage(payloadPowerPercent + busPowerPercent + edgePowerPercent)}%
                                    </div>
                                </div>}
                            {(!props?.showPercent) && <img src={require('../../assets/images/icons/power.png')} alt={''} />}
                            <div className="d-flex flex-column">
                                {/* {!props?.showPercent && <span className="stats-value">{truncateNumber((Number(powerUsage) + Number(edgePowerUsage)), 2)} W</span>} */}
                                {!props?.showPercent && <span className="stats-value">{aopCalculateUsage} W</span>}
                                {props?.showPercent && <span className="stats-value">{truncateNumber(powerUsage + edgePowerUsage + busPowerUsage, 2)} W</span>}
                                <span className="stats-title">{!props?.showPercent ? 'OAP' : 'Power'}</span>
                            </div>
                        </div>
                        {props?.showPercent &&
                            <div className="mt-2" onClick={() => openCloseDD()}>
                                {!ddToggle && <i className="fe fe-chevron-down fs-18"></i>}
                                {ddToggle && <i className="fe fe-chevron-up fs-18"></i>}
                            </div>}
                    </div>

                    {ddToggle &&
                        <div className="stats-float">
                            <div className="position-relative">
                                <button
                                    onClick={() => setDDToggle(false)}
                                    type="button"
                                    className="btn-close position-absolute"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <i className="fe fe-x-circle fs-25" />
                                </button>
                                <div className="d-flex">
                                    <UpdateDonut {...{
                                        series: [bus_size_usage, payload_size_usage, edge_size_usage, un_used_size],
                                        labels: ['Bus', 'Payloads', 'Edge', 'Unused'],
                                        title: 'Size',
                                        legend: false,
                                        id: Math.random()
                                    }} />
                                    <UpdateDonut {...{
                                        series: [bus_weight_usage, payload_weight_usage, edge_weight_usage, un_used_weight],
                                        labels: ['Bus', 'Payloads', 'Edge', 'Unused'],
                                        title: 'Mass',
                                        legend: false,
                                        id: Math.random()
                                    }} />
                                    <UpdateDonut {...{
                                        series: [bus_power_usage, payload_power_usage, edge_power_usage, un_used_power],
                                        labels: ['Bus', 'Payloads', 'Edge', 'Unused'],
                                        title: 'Power',
                                        legend: false,

                                        id: Math.random()
                                    }} />
                                </div>
                            </div>

                        </div>}

                </div>}

                <div className="d-flex position-absolute" style={{ right: 50, }}>
                    {props.isButton && (
                        <Button
                            variant="primary"
                            className="btn-default tablebutton me-2 my-1"
                            onClick={props.onButtonClick}
                        >
                            {props.isButton}
                        </Button>
                    )}
                </div>
            </div>
            {/* <!-- PAGE-HEADER END --> */}
        </div>
    );
};

export default PageHeader;
