import { Fragment } from 'react'
import useOnBoardSchedule, { ScheduleData } from '../../../../hooks/useOnBoardSchedule'
import {
    FilterSvg,
    GroundTaskSvg,
    PayloadTaskSvg,
    SearchSvg,
    SubSystemSvg,
    TaskCompleteSvg,
    TaskDeleteSvg,
} from '../../modals/Svgs'
import './Schedule.scss'
import useUrlNavigation from '../../../../hooks/useUrlNavigation'
import useUrlParams from '../../../../hooks/useUrlParams'
import { get_navigator_button_by_dashboard } from '../utils'
import useContacts from '../../../../hooks/useContacts'
import useViewer from '../../../../hooks/useViewer'
import { get_formated_time, get_task_status_icon } from '../../../../utils'
import CustomToolTip from '../../../../../CommonComponents/CustomToolTip'
import EmptyPage from '../../../../custom_components/EmptyPage'
import UploadScheduleButton from '../../../../custom_components/UploadScheduleButton'
import GenerateScheduleBurtton from '../../../../custom_components/GenerateScheduleBurtton'
import { toast } from 'react-toastify'

const OnboardSchedule = () => {
    const { tab_name, dashboard } = useUrlParams()
    const { selectedScheduleTask, allOnBoardSchedule, select_schedule, selectedSchedule, delete_onboard_schedule } = useContacts()
    const { navigate_to } = useUrlNavigation()
    const { current_simulation_time } = useViewer()
    const { scheduleDetails, scheduleData, select_schedule_data } = useOnBoardSchedule()

    const get_schedule_status = (status: string) => {
        if (status === 'YET_TO_BE_UPLOADED') {
            return {
                label: 'Yet to be uploaded',
                status: 'yet_to_be_uploaded'
            }
        } else if (status === 'EXPIRED') {
            return {
                label: 'Expired',
                status: 'expired'
            }
        } else if (status === 'ABORTED') {
            return {
                label: 'Superceeded',
                status: 'aborted'
            }
        } else if (status === 'COMPLETED') {
            return {
                label: 'Completed',
                status: 'completed'
            }
        } else {
            return {
                label: 'Ongoing',
                status: 'ongoing'
            }
        }
    }

    const delete_schedule = (schedule: any) => {
        const scheduleId = schedule?.['ScheduleId']
        if (!scheduleId) return
        delete_onboard_schedule(scheduleId).then((res) => {
            toast.success('Schedule deleted successfully')
        }).catch((err) => {
            toast.error('Failed to delete schedule')
        })
    }

    return (
        <div className='planning__container__body'>
            {scheduleData?.length > 0 &&
                <Fragment>
                    <div className='planning__container__header'>
                        <div className='planning__header__left__container'>
                            {get_navigator_button_by_dashboard(dashboard!).map((btn) => {
                                return (
                                    <button className={`button ${tab_name === btn.name && 'active'}`}
                                        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                            e.stopPropagation()
                                            navigate_to(btn.name)
                                        }}
                                    >{btn.label}</button>
                                )
                            })}
                        </div>
                        {dashboard === 'operate' &&
                            <div className='operate__planning__header__right__container'>
                                <UploadScheduleButton />
                                <GenerateScheduleBurtton />
                            </div>
                        }
                    </div>
                    <div className='schedule__container'>
                        <Fragment>
                            {dashboard === 'operate' &&
                                <Fragment>
                                    <div className='left__schedule__panel__container'>
                                        <div className='left__schedule__list__panel__container__header'>
                                            <span>List of Schedules</span>
                                            <div className='button__section'>
                                                <button><SearchSvg color='#F0F0F0' /></button>
                                                <button><FilterSvg /></button>
                                            </div>
                                        </div>
                                        <div className='right__schedule__panel__container__table__body gap-3'>
                                            {Object.keys(allOnBoardSchedule).map(key => {
                                                const all_schedules = allOnBoardSchedule?.[key]
                                                return (
                                                    <div className='d-flex flex-column gap-2'>
                                                        <span className='opacity-75'>{key}</span>
                                                        {all_schedules?.map(schedule => {
                                                            const status = get_schedule_status(schedule?.['status'])
                                                            return (
                                                                <div className={`schedule__details__container ${key === 'Ongoing' && 'running'} ${selectedSchedule === schedule?.['ScheduleId'] && 'active'}`}
                                                                    onClick={() => { select_schedule(schedule) }}
                                                                >
                                                                    <div className='details__container'>
                                                                        <div className='d-flex align-items-center justify-content-between w-100'>
                                                                            <span className={`details__container__name ${status.status}`}>{schedule?.['ScheduleName']}</span>

                                                                            <div className='details__container__button__container'>
                                                                                {status?.status === 'completed' && <TaskCompleteSvg />}
                                                                                {(status?.status === 'aborted' || status?.status === 'expired') &&
                                                                                    <CustomToolTip title='Delete' placement='top'>
                                                                                        <button className='details__container__button' onClick={() => { delete_schedule(schedule) }}>
                                                                                            <TaskDeleteSvg />
                                                                                        </button>
                                                                                    </CustomToolTip>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div className='d-flex align-items-center justify-content-between w-100'>
                                                                            <span className={`details__container__time`}>{schedule?.['StartTime']}</span>
                                                                            <span className={`details__container__status ${status.status}`}>{status.label}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    <div className='schedule__panel__separator' />
                                </Fragment>
                            }
                            <div className='left__schedule__panel__container'>
                                <div className='left__schedule__panel__container__header'>
                                    <span className='header__title'>
                                        <CustomToolTip title={scheduleDetails.name} placement='top'>
                                            {scheduleDetails.name}
                                        </CustomToolTip>
                                    </span>
                                    <div className='header__time__details'>
                                        <span>{scheduleDetails.start_time && `${get_formated_time(scheduleDetails.start_time)}`}</span>
                                    </div>

                                </div>
                                <div className='right__schedule__panel__container__table__header'>
                                    <span>Tasks</span>
                                    <span>{scheduleData?.length}</span>
                                </div>
                                <div className='right__schedule__panel__container__table__body'>
                                    {scheduleData?.length > 0 && scheduleData.map((task: ScheduleData, index) => {
                                        const current_time = current_simulation_time / 1000
                                        const task_start_time = task.startTime;
                                        const task_end_time = task.endTime;
                                        const task_type_icon = task.type === 'Payload Task' ? <PayloadTaskSvg /> : <GroundTaskSvg />
                                        const task_status_icon = get_task_status_icon(task_start_time, task_end_time, current_time)
                                        const sub_name = task.type === 'Payload Task' ? `${task?.['properties']?.['payloadName']} | ${task?.['properties']?.['sequenceName']}` : `${task?.['properties']?.['groundStationName']}`
                                        const task_running = (current_time >= task_start_time && current_time <= task_end_time) ? 'running' : ''

                                        return (
                                            <Fragment>
                                                <div className={`schedule__details__container ${task_running} ${selectedScheduleTask?.schedule_data?.startTime === task?.startTime && 'active'}`}
                                                    onClick={() => select_schedule_data(task)}
                                                >
                                                    <div className='schedule__icons'>
                                                        {task_status_icon}
                                                        {task_type_icon}
                                                    </div>
                                                    <div className='details__container'>
                                                        <span className='details__container__name'>
                                                            <CustomToolTip title={task?.['taskName']} placement='top'>
                                                                {task?.['taskName']}
                                                            </CustomToolTip>
                                                        </span>
                                                        <span className='details__container__sub_name'>
                                                            <CustomToolTip title={sub_name} placement='top'>
                                                                {sub_name}
                                                            </CustomToolTip>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className='schedule__details__container__separator' />
                                            </Fragment>
                                        )
                                    })}
                                </div>
                            </div>
                            <div className='schedule__panel__separator' />
                            <div className='right__schedule__panel__container'>
                                <div className='right__schedule__panel__container__header'>
                                    <div className='header__title__details__container'>
                                        <div className='header__title__details'>
                                            <div className='d-flex gap-1'>
                                                {selectedScheduleTask?.schedule_data?.type === 'Payload Task' ? <PayloadTaskSvg /> : <GroundTaskSvg />}
                                                <span>{selectedScheduleTask?.schedule_data?.['taskName']}</span>
                                            </div>
                                            <span className='header__subtitle'>{selectedScheduleTask?.schedule_data?.type === 'Payload Task' ? `${selectedScheduleTask?.schedule_data?.['properties']?.['payloadName']} | ${selectedScheduleTask?.schedule_data?.['properties']?.['sequenceName']}` : `${selectedScheduleTask?.schedule_data?.['properties']?.['groundStationName']}`}</span>
                                        </div>
                                    </div>
                                    <div className='d-flex gap-3 align-items-center'>
                                        <div className='header_badge'>
                                            <span>{get_formated_time(selectedScheduleTask?.schedule_data?.['startTime'])}</span>
                                        </div>
                                        <span>to</span>
                                        <div className='header_badge'>
                                            <span>{get_formated_time(selectedScheduleTask?.schedule_data?.['endTime'])}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className='right__schedule__panel__container__table__header'>
                                    <span>Telecommands</span>
                                    <span>{selectedScheduleTask?.schedule_data?.['commandsList']?.length}</span>
                                </div>
                                <div className='right__schedule__panel__container__table__body'>
                                    {selectedScheduleTask?.schedule_data &&
                                        <Fragment >
                                            <div className={`telecommands__details__container `}>
                                                <div className='telecommands__details__header'>
                                                    <div className='telecommands__details__header__item'> SUBSYSTEM & COMMAND</div>
                                                    <div className='telecommands__details__header__item'>START TIME</div>
                                                    <div className='telecommands__details__header__item'>DURATION</div>
                                                </div>
                                                <div className='telecommands__details__body__container'>
                                                    {selectedScheduleTask?.schedule_data?.['commandsList']?.map((command, id) => {
                                                        const current_time = current_simulation_time / 1000
                                                        const sub_task_start_time = command.epoch;
                                                        const sub_task_end_time = command.epoch + command.duration;
                                                        const sub_task_status_icon = get_task_status_icon(sub_task_start_time, sub_task_end_time, current_time);
                                                        // const sub_task_running = command?.['status'] === 'active' ? 'running' : ''
                                                        const sub_task_running = (current_time >= sub_task_start_time && current_time <= sub_task_end_time) ? 'running' : ''
                                                        return (
                                                            <Fragment key={id}>
                                                                <div className={`telecommands__details__body ${sub_task_running}`}>
                                                                    <div className='telecommands__details__body__item'>
                                                                        <div className='schedule__icons'>{sub_task_status_icon}</div>
                                                                        <div className='details__container'>
                                                                            <div className='command__title__container'>
                                                                                <SubSystemSvg />
                                                                                <span>{command?.['name']}</span>
                                                                            </div>
                                                                            <span>{command?.['subsystem']}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className='telecommands__details__body__item'>
                                                                        <span>{get_formated_time(command?.['epoch'])}</span>
                                                                    </div>
                                                                    <div className='telecommands__details__body__item'>
                                                                        <span>{command?.['duration']} sec</span>
                                                                    </div>
                                                                </div>
                                                            </Fragment>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </Fragment>
                                    }
                                </div>
                            </div>
                        </Fragment>
                    </div>
                </Fragment>
            }
            {scheduleData?.length === 0 && <EmptyPage content='Task Not Scheduled' >
                <div className='planning__container__header'>
                    <div className='planning__header__left__container'>
                        {get_navigator_button_by_dashboard(dashboard!).map((btn) => {
                            return (
                                <button className={`button ${tab_name === btn.name && 'active'}`}
                                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                        e.stopPropagation()
                                        navigate_to(btn.name)
                                    }}
                                >{btn.label}</button>
                            )
                        })}
                    </div>
                    {dashboard === 'planning' &&
                        <div className='operate__planning__header__right__container'>
                            <UploadScheduleButton />
                            <GenerateScheduleBurtton />
                        </div>
                    }
                </div>
            </EmptyPage>}
        </div>
    )
}

export default OnboardSchedule