import { useEffect, useState } from 'react';
import CommandCenterService from '../../../service/CommandCenterService';
import useUrlParams from "../hooks/useUrlParams";
import useTrueTwin from "../hooks/useTrueTwin";
import OperateService from '../../../service/OperateService';
interface Comment {

}

const useConsole = () => {
    const { TrueTwin } = useTrueTwin()
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const [busComponentOptions, setBusComponentOptions] = useState<any>([])
    const [busCommandOptions, setBusCommandOptions] = useState<any>([])
    const [allBusCommands, setAllBusCommands] = useState<any>([])
    const [allPayloadComponents, setAllPayloadComponents] = useState<any>([])
    const [commandDetails, setCommandDetails] = useState<any | null>(null);
    const [formData, setFormData] = useState<any | null>(null);
    const [linkId, setLinkId] = useState<string | null>(null);

    const { sat_id, truetwin_id, scenario_id, dashboard } = useUrlParams()


    const fetchBusComponents = async () => {
        try {
            const res: any = await CommandCenterService.getObject();
            if (res.data) {
                const response: any[] = res.data
                let busCommands: any = [];
                let allBusCategories: any = [];
                const map = new Map();

                response.forEach((m: { funcCategory: string }) => {
                    if (!map.has(m.funcCategory)) {
                        map.set(m.funcCategory, true); // or you can set any value you need
                        allBusCategories.push(m);
                    }
                });
                let filterdBusCategories: { label: string; value: string }[] = [{ label: 'All', value: 'All' }]
                allBusCategories.filter((cat: { funcCategory: string }) => {
                    filterdBusCategories = [
                        ...filterdBusCategories,
                        { label: cat?.["funcCategory"], value: cat?.['funcCategory'] },
                    ]
                })
                // console.log("hook console", filterdBusCategories)
                setBusComponentOptions(filterdBusCategories);
                res.data.forEach((command: any) => {
                    busCommands = [
                        ...busCommands,
                        { label: command?.["sequenceData"]?.['commandId'] + '-' + command?.["name"], value: command },
                    ];
                });

                setBusCommandOptions(busCommands);
                setAllBusCommands(busCommands);
            }
        } catch (err) {
            console.log(err);
        }
    };


    const fetchBusCommands = (components: string) => {
        if (components !== 'All') {
            let filterBusCommands: any[] = []
            allBusCommands.filter((command) => {
                if (command?.value?.funcCategory === components) {
                    filterBusCommands = [
                        ...filterBusCommands,
                        command,
                    ]
                }
            })
            setBusCommandOptions(filterBusCommands);
        } else {
            setBusCommandOptions(allBusCommands);
        }
    }
    const fetchPayloadComponents = async () => {
        setLoading(true);

        try {
            const res = await CommandCenterService.getSatelliteById(sat_id);
            if (res?.data) {

                const payloads = res.data.versions[0].assembly.Payloads.map((payload, index) => (
                    {
                        label: payload['atmos-data'].name,
                        value: {
                            payload: TrueTwin?.['remoteApp']?.['AppData']?.[index],
                            sequence: payload
                        }

                    }));
                setAllPayloadComponents(payloads);
            }
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchBusComponents();
        if (TrueTwin) {
            fetchPayloadComponents();
        }
    }, [TrueTwin])

    const postData = async (payload: any) => {
        if (dashboard === 'operate') {
            payload[0].LinkId = linkId
        }
        setLoading(true);
        setError(null);
        try {
            await CommandCenterService.postCommandDetails(payload);
            {/* This commented code might be needed in the future if commands need to be cleared after execution. */ }
            // sessionStorage.removeItem('formData'); // Clear formData from sessionStorage after successful execution of postCommandDetails
            const currentDataEvent = new Event('loadCurrentData');
            window.dispatchEvent(currentDataEvent);
            // await fetchData();
        } catch (err: any) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };


    const getCommandDetails = async (CommandId) => {
        setLoading(true);
        try {
            const res = await CommandCenterService.getCommandDetailsById(truetwin_id, sat_id, scenario_id, CommandId?.['CommandUid']);
            setCommandDetails(res);
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    };

    const getBackdoorProvider = () => {
        OperateService.getProvider(sat_id)?.then((res) => {
            const provider = res.data?.['Backdoor'];
            const band = Object.keys(provider)?.[0];
            const uplink = provider?.[band]?.['Uplink'];
            setLinkId(uplink)
        }).catch((err) => {
            console.error(err);
            setLoading(false);
        });
    }

    useEffect(() => {
        if (sat_id && dashboard === 'operate') {
            getBackdoorProvider()
        }
    }, [sat_id, dashboard])


    return {
        loading, error, postData, fetchBusCommands, busComponentOptions, busCommandOptions, allBusCommands, allPayloadComponents, getCommandDetails, commandDetails, setFormData, formData,
    };
};

export default useConsole;