import { FC, useEffect, useMemo, useState } from "react"
import * as Cesium from "cesium"
import './GroundStationCard.css'
import { AltitudeSvg, LatLongSvg } from "./svgs/Svgs";
import { truncateNumber } from "./CommonFunctions";

interface GsProps {
    station: any;
    viewer: any
}

const GroundStationCard: FC<GsProps> = ({ station, viewer }) => {

    const [width, set_width] = useState(500);

    if (viewer && station) {
        let latitude = Number(station?.properties?.latitude);
        let longitude = Number(station?.properties?.longitude);

        let cartographic = Cesium.Cartographic.fromDegrees(longitude, latitude);
        let cartesianPosition = Cesium.Cartographic.toCartesian(cartographic);
        const popupPosition = Cesium.SceneTransforms.wgs84ToWindowCoordinates(
            viewer.scene,
            cartesianPosition
        );

        let outside_window_x = (popupPosition.x + (width + 10)) > window.innerWidth;
        let top = `${popupPosition.y}px`;
        let left = outside_window_x ? `${popupPosition.x - (width + 10)}px` : `${popupPosition.x + 10}px`;

        const show_details = () => {
            const element = document.querySelector('.popup-modal');
            if (element) {
                (element as HTMLElement).classList.add('expand');
                set_width(600)
            }
        }
        const hide_details = () => {
            const element = document.querySelector('.popup-modal');
            if (element) {
                (element as HTMLElement).classList.remove('expand');
                set_width(500)
            }
        }
        const station_name = station?.['properties']?.['country'] ? `${station?.['groundStationName']}, ${station?.['properties']?.['country']}` : station?.['groundStationName']
        const provider_name = (station?.['properties']?.['provider'] === undefined || station?.['properties']?.['provider'] === "" || station?.['properties']?.['provider'] === "Untitled") ? "-" : `${station?.['properties']?.['provider']}`
        return (
            <div className="popup-modal"
                style={{
                    width: `${width}px`,
                    display: station ? 'flex' : 'none',
                    cursor: 'default',
                    left: left,
                    top: top,
                    transition: 'all 1s ease',
                    zIndex: 9999,
                }}
            >
                <div className="popup-modal-header">
                    <div className='d-flex align-items-start flex-column w-30'>
                        <div className='d-flex align-items-center w-98'>
                            <span className='fs-14 w-auto color-F0F0F0 text-truncate' title={station_name}>
                                {station_name}
                            </span>

                        </div>
                        <span className='fs-12 color_history text-truncate'>
                            Ground Station
                        </span>
                    </div>
                    <div className='align-items-center justify-content-center gap-2 w-40 d-flex show__position fs-12'>
                        <div className="d-flex align-items-center gap-2">
                            <LatLongSvg />
                            <span>{station?.properties?.latitude === undefined ? 'NA' : truncateNumber(station?.properties?.latitude, 2)}<sup>o</sup>, {station?.properties?.longitude === undefined ? 'NA' : truncateNumber(station?.properties?.longitude, 2)}<sup>o</sup></span>
                        </div>
                        <div className="d-flex gap-2">
                            <div className="d-flex align-items-center">
                                <AltitudeSvg />
                                <span className="color_history">H</span>
                            </div>
                            <span>{station?.properties?.altitude === undefined ? '0m' : truncateNumber(station?.properties?.altitude, 2) + ' m'}</span>
                        </div>
                    </div>
                    <div className='d-flex align-items-center flex-column w-30'>
                        <div className='d-flex w-98 flex-column align-items-end justify-content-center'>
                            <span className='fs-14 w-auto color-F0F0F0 text-truncate' title={provider_name}>
                                {provider_name}
                            </span>
                            <span className='fs-12 color_history w-auto '>
                                Provider
                            </span>
                        </div>
                    </div>

                </div>
                <div className="popup-modal-body">
                    <div className="w-100 align-items-center justify-content-between hide_more_details px-2 py-3">
                        <div className="d-flex align-content-center gap-1">
                            {station?.['properties']?.['freqbands']?.map((band: any, index) => {
                                return (
                                    <div className='color_history fs-12 px-2 py-1 rounded-1' style={{ background: 'rgba(0, 0, 0, 0.20)' }}>{band?.split('-')?.[0]}</div>
                                )
                            })}
                        </div>
                        <div className="cursor-pointer fs-12" onClick={show_details}>View More</div>
                    </div>
                    <div className='w-100 align-items-center flex-column gap-2 pr-4 py-3 show_more_details'>
                        <div className='d-flex align-items-center justify-content-between w-100'>
                            <span className='color_history fs-13 w-10 align-items-center justify-content-center d-flex'>Bands</span>
                            <span className='color_history fs-13 w-20 align-items-center justify-content-center d-flex'>Rx Freq.</span>
                            <span className='color_history fs-13 w-20 align-items-center justify-content-center d-flex'>Tx Freq.</span>
                            <span className='color_history fs-13 w-15 align-items-center justify-content-center d-flex'>G/T (dB/K)</span>
                            <span className='color_history fs-13 w-20 align-items-center justify-content-center d-flex'>EIRP (dBW)</span>
                            <span className='color_history fs-13 w-15 align-items-center justify-content-center d-flex'>Polarization</span>
                        </div>
                        {station?.['properties']?.['freqbands']?.map((band: any, index) => {
                            return (
                                <div className='d-flex align-items-center justify-content-between w-100' key={index}>
                                    <span className='color_history align-items-center justify-content-center d-flex fs-12 w-10'>{band?.split('-')?.[0]}</span>
                                    <span className='fs-12 w-20 align-items-center justify-content-center d-flex color-text'>
                                        {station?.['properties']?.['rxFreq']?.[`${band?.split('-')[0]}freq`]?.['min'] ? `${station?.['properties']?.['rxFreq']?.[`${band?.split('-')[0]}freq`]?.['min']} - ${station?.['properties']?.['rxFreq'][`${band?.split('-')[0]}freq`]?.['max']}` : '-'}
                                    </span>
                                    <span className='fs-12 w-20 align-items-center justify-content-center d-flex color-text'>
                                        {station?.['properties']?.['txFreq']?.[`${band?.split('-')[0]}freq`]?.['min'] ? `${station?.['properties']?.['txFreq']?.[`${band?.split('-')[0]}freq`]?.['min']} - ${station?.['properties']?.['txFreq'][`${band?.split('-')[0]}freq`]?.['max']}` : '-'}
                                    </span>
                                    <span className='fs-12 w-15 align-items-center justify-content-center d-flex color-text'>{station?.['properties']?.[`${band?.split('-')[0]}_GT`] ? station?.['properties']?.[`${band?.split('-')[0]}_GT`] : '-'}</span>
                                    <span className='fs-12 w-20 align-items-center justify-content-center d-flex color-text'>{station?.['properties']?.[`${band?.split('-')[0]}_EIRP`] ? station?.['properties']?.[`${band?.split('-')[0]}_EIRP`] : '-'}</span>
                                    <span className='fs-12 w-15 align-items-center justify-content-center d-flex color-text' title={station?.['properties']?.[`polarization`]}>
                                        {station?.['properties']?.[`polarization`] ?
                                            station?.['properties']?.[`polarization`]?.length > 7 ? station?.['properties']?.[`polarization`]?.slice(0, 7) + '...' : station?.['properties']?.[`polarization`]
                                            : '-'
                                        }
                                    </span>
                                </div>
                            )
                        })}
                        <div className="w-100 d-flex justify-content-end" >
                            <span className="fs-12 cursor-pointer color-atmos pe-2" onClick={hide_details}> View Less</span>
                        </div>
                    </div>
                </div>

            </div>
        )

    }
    return null
}

export default GroundStationCard